<template>
  <v-container>
    <v-card class="mx-auto cardstyle" max-width="800" raised>
    <v-img
      height="250"
      src="/static/header.jpg"
    ></v-img>      

      <v-card-title>Inscripción GIRA Mujeres</v-card-title>
      <v-card-text>
        <p class="text-body-1">
          Bienvenida a GIRA Mujeres 5ª edición. Antes de nada, ¡GRACIAS! por mostrar interés por participar en nuestro programa. Como ya sabrás estamos buscando a las emprendedoras que quieran dar forma a ideas de emprendimiento o hacer crecer sus negocios.</p>
        <!--
        <p class="text-body-1">
          <strong>Para solicitar tu plaza</strong> en la formación de GIRA Mujeres necesitamos que:<br/><br/>
          1.	<strong>Elijas tu categoría:</strong> deberás seleccionar una de las dos opciones que te planteamos, piensa bien la categoría en la que quieres participar: ¿eres una emprendedora ya en activo con tu proyecto en marcha? ¿o por el contrario tienes un espíritu emprendedor y/o una buena idea, pero aún no la has llevado a cabo y quieres emprender?.<br/><br/>
          2.	<strong>Cumplimentes el cuestionario:</strong> te recomendamos que te tomes tu tiempo para desarrollarlo con mimo, ya que nos permitirá conocer si cumples con los requisitos para ser parte del programa.
        </p>
       -->
     </v-card-text>
      <v-card-text style="text-align: center">
        <strong>Lamentamos comunicarte que las plazas están cubiertas.<br/>Estate atenta a la próxima convocatoria de la categoría Tengo un negocio de GIRA Mujeres.<br/>¡Si tienes una ilusión, tienes nuestro apoyo!</strong>
      </v-card-text>
      <!--
      <v-card-actions>
        <v-container>
        <v-row>
        <v-col class="text-center" cols="12" sm="6"><v-btn color="error" large :block="$vuetify.breakpoint.xsOnly" @click="quiero()"> Quiero emprender</v-btn></v-col>
        <v-col class="text-center" cols="12" sm="6"><v-btn color="error" large :block="$vuetify.breakpoint.xsOnly" @click="tengo()"> Tengo un negocio </v-btn></v-col>
        </v-row>
        </v-container>
      </v-card-actions>
      -->
    </v-card>
    <v-container>
      <v-row>
        <v-col class="text-center"><a href="/static/bases-v-edicion-giramujeres.pdf" target="_blank">BASES DE PARTICIPACIÓN V EDICIÓN GIRA MUJERES DE COCA-COLA</a></v-col>
      </v-row>
      <v-row>
        <v-col class="text-center"><a href="/static/folleto-cadena-valor.pdf" target="_blank">FOLLETO CADENA DE VALOR</a></v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
  mounted: function () {
    console.log('mounted')
    this.$store.state.perfil = null
  },
  methods: {
    quiero: function () {
      this.$store.state.perfil = 'quiero-emprender'
      this.$router.push({name: 'General'})
    },
    tengo: function () {
      this.$store.state.perfil = 'tengo-un-negocio'
      // this.$router.push({name: 'General'})
    }
  }
};
</script>
<style scoped>
.cardstyle {
  background-color: white;
}
</style>
